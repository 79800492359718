<template>
<div class="card">
  <div>
    <div class="container">
      <div class="card-img">
        <div>
          <img src="../assets/images/img/tv-home.png" alt="tv">
        </div>
        <div class="cart-img-buttons">
          <button
              class="card-text"
              :style="`padding: ${12 - idx}px ${21 - idx}px`"
              :class="{'active-screen-button' : activeSize === idx}"
              v-for="(size, idx) in screenSize"
              :key="idx"
              @click="getActiveSize(idx)"
          >{{size}} <span>,,</span></button>
        </div>
      </div>
      <div class="card-content">
        <h2 class="card-title">THE PRODUCT NAME</h2>
        <h3 class="card-title">Subtitle</h3>
        <ul class="card-top-text">
          <li class="card-text">90 x 60 x 2 cm Backboard</li>
          <li class="card-text">Adjustable Height</li>
          <li class="card-text">Portable Build in Wheels</li>
          <li class="card-text">Base Cab be Filled</li>
        </ul>
        <ul class="card-bottom-text">
          <li class="card-text">Product Dims: 102 x 35.43 x 23.62</li>
          <li class="card-text">Product Weight: 26.35</li>
          <li class="card-text">Gift Box Dims: 27.24 x 8.84 x 36.81</li>
          <li class="card-text">Gift Box Weight: 28.66</li>
          <li class="card-text">Master Carton Dims: N/A</li>
          <li class="card-text">Master Carton Weight: N/A</li>
          <li class="card-text">Master Carton Qty: 1</li>
          <li class="card-text">20’: 197 / 40’: 400 / 40’HQ: 430</li>
        </ul>
      </div>
    </div>
  </div>
  <Footer/>
</div>
</template>

<script>
import Footer from "@/components/Nav/Footer";
export default {
  name: "Card",
  components: {Footer},
  mounted() {
    window.scrollTo(0,0)
  },
  data () {
    return{
      screenSize: ['43', '40', '39', '32', '28'],
      activeSize: null,
    }
  },
  methods:{
    getActiveSize (idx) {
      this.activeSize = idx
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_vars.scss";
.card{
   & > div:first-child{
     width: 100%;
     background-color: $white;
     .container{
       width: 100%;
       display: flex;
       justify-content: space-between;
       .card-img{
         max-width: 661px;
         width: 100%;
         margin: 129px 0 193px 69px;
         & > div:first-child{
           width: 100%;
           height: 369px;
           & > img{
             width: 100%;
             height: 100%;
             object-fit: cover;
           }
         }
         .cart-img-buttons{
           display: flex;
           align-items: flex-end;
           margin: 26px auto 0;
           width: fit-content;
           button{
             border: 1px solid #D1D1D1;
             display: flex;
             justify-content: center;
             margin-left: 24px;
             color: #000;
             &:first-child{
               margin-left: 0;
             }
             & > span{
               line-height: 0;
             }
             &:hover{
               color:  $white;
               background-image: $blueBg;
               transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
               transition-duration: 300ms;
               transition-delay: 150ms;
             }
           }
           .active-screen-button{
             color:  $white;
             background-image: $blueBg;
           }
         }
       }
       .card-content{
         max-width: 555px;
         width: 100%;
         margin: 61px 40px 0 0;
         .card-title{
           font-weight: 600;
           &:nth-child(2){
             font-weight: 400;
             margin-top: 8px;
           }
         }
         .card-top-text{
           margin: 45px 0 0 10px;
           list-style-type: disc;
           list-style-position: inside;
           li{
             margin-top: 8px;
             &:first-child{
               margin-top: 0;
             }
           }
         }
         .card-bottom-text{
           width: 100%;
           margin-top: 45px;
           padding: 25px 0 23px 36.6px;
           border: 1px solid #D1D1D1;
           li{
             margin-top: 5px;
             &:first-child{
               margin-top: 0;
             }
           }
         }
       }
     }
  }
}
</style>